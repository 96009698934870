.container{
    display: flex;
    flex-flow: row;
}
.password_form_container{
    display: flex;
    flex-flow: column;
    width: 100%;
}
.change {
    width: 40%;
    margin: 2rem auto;
    padding: 2rem;
    text-align: left;

}
.instructions{
    width: 40%;
    height: 20%;
    margin: 0.1rem auto;
    text-align: left;
    font-size: 12px;
}

.actions {
    width: 100%;
    text-align: center;
}

.error {
    padding: 5px;
    background-color: rgba(255, 0, 0, 0.366);
}
.backnav{
    padding: 0.5rem;
    text-align: center;
    position: relative;
    margin:1rem;
    width: 10%;
    height: 10%;
    text-decoration: none;
    color: white;
    display: flex;
    border-radius: 12px;
    background-color: #14213d;
}
.backnav h6{
    justify-content: center;
    align-content: center;
}



@media (max-width: 768px) {
    .container{
        flex-flow: column;
    }
    .change{
        margin: 10%;
        width: 80%;
    }
    .backnav{
        width: 30%;
        height: 5%;
    }
    .instructions {
        margin: 10%;
        width: 80%;
        height: 20%;
        margin-top: 0.1rem;
        margin-bottom: 2rem;
        text-align: left;
        font-size: 12px;
    }

}

