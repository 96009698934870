.control {
    margin: 1rem 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;
}

.control label,
.control input {
    display: block;
}

.control label {
    font-weight: bold;
    flex: 1;
    color: #464646;
    margin-bottom: 0.5rem;
}

.control input {
    flex: 3;
    font: inherit;
    padding: 0.35rem 0.35rem;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.control input:focus {
    outline: none;
    border-color: #14213d;
    background: #e5e5e5;
}

.control .invalid {
    border-color: red;
    background: #fbdada;
}