.topics {
    margin-top: 1rem;
    margin-left: 1rem;
    text-decoration: none;
}

.topic_header {
    display: flex;
    flex-flow: row;
    padding: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.26);
    gap: 1rem;
    background-color: rgb(218, 224, 224);
    font-size: 16px;
    border-radius: 5px 5px 0px 0px;
}
.header{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}

.topic_header h6 {
    opacity: 0.6;
}
.icon {
    align-self: center;
    display: flex;
    left: 1rem;
    color: blue;
    background-color: #FCA311;
    padding: 0.5rem;
}

.icon:hover {
    cursor: pointer;
}

.navlist {
    text-decoration: none;
    text-align: left;
    background-color: aliceblue;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0px 0px 5px 5px;
}

.navlist ul {
    text-decoration: none;
    list-style: none;
}

.navlist p:hover {
    cursor: pointer;
}

/* .navlist p:active {
    background-color: #ab6c05;
} */

.link_row{
    display: flex;
    justify-content: space-between;
}
.link_row:hover{
    background-color: #d68d18;
}