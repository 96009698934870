.main-header {
    top: 0;
    left: 0;
    right:0;
    width: 100%;
    display: flex;
    flex-flow: row;
    background: #FCA311;
    justify-content: space-between;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.module_name {
    left:0;
    right: 0;
    color: #14213d;
    top: 2.4rem;
    text-align: center;
    align-self: center;
    height: fit-content;
    position: absolute;
    margin: 0px;
    font-family: 'monospace';
    text-transform: capitalize;
    letter-spacing: 0.3rem;
}

.banner_img {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
    background-color: transparent;
    margin-left: 2rem;
}
.navContainer{
    top:10%;
    margin-right:5%;
    width: 30%;
    font-size: 12px;
    position: relative;
    z-index:index 2;
}

@media (max-width:700px){
    .main-header{
        flex-flow: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    .main-header img{
        width: 6rem;
        height: 4rem;
        align-self: center;
    }
    .module_name{ 
        margin-top: 2rem;
        font-size: 20px;
        text-align: center;
        align-self: center;
    }
    .navContainer{
        font-size:8px;
    }
}
@media(max-width: 1000px){
    .module_name{
        top:2rem;
        font-size: 22px;
    }
    .banner_img{
        margin-left: 1rem;
    }
    .navContainer{
        margin-left: 0%;
    }
}