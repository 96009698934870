.courses_container {
    width: 90%;
    max-width: 40rem;
    text-wrap: wrap;
    margin: 0px;
}

.course {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 40rem;
    margin: 1rem;
    overflow: hidden;
    width: 95%;
}

.course:hover {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.7);
    transition-delay: 100ms;
}

.course h6,h5,p {
    opacity: 0.7;
    text-transform: uppercase;
}

.course h2 {
    letter-spacing: 0.1rem;
    margin: 10px;
}

.course_preview {
    background-color: #14213d;
    color: #fff;
    padding: 1.5rem;
    width: 10rem;
    align-content: center;
}

/* .course_preview h2 {
    margin-top: 10px;
    font-size: 18px;
} */

.course_preview a {
    color: #fff;
    display: inline-block;
    font-size: 12px;
    opacity: 0.6;
    text-decoration: none;
}

.course_info {
    padding-top: 1.5rem;
    position: relative;
    width: 90%;
    font-size: 12px;
    margin-left: 0%;
}

.progress_container {
    position: absolute;
    top: 30px;
    right: 15px;
    text-align: right;
    width: 100px;
}

.totalProgress {
    background-color: #ddd;
    border-radius: 3px;
    height: 5px;
    width: 100%;
}

.progress_text {
    font-size: 0.6rem;
    opacity: 0.6;
    letter-spacing: 1px;
}

.btn {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 16px;
    position: relative;
    margin: 15px;
    bottom: 15px;
    right: 15px;
    letter-spacing: 1px;
    width: 8rem;
    height: 2rem;
}

@media (max-width:700px) {

    .courses_container{
        width: 100%;
    }
    .course {
        width: 90%;
    }

    .course_preview {
        width: 6rem;
    }

    .progress_container {
        left: 30%;
        top: 10%;
        position: absolute;
    }

    .course_info {
        font-size: 10px;
    }

    .btn {
        margin: 10px;
        bottom: 10px;
        right: 10px;
    }
    .course_preview{
        font-size: 10px;
        font-size-adjust: 0.58;
    }
}