.home {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-flow: column;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
}
.outlet{
    width: 100%;
    height: 100%;
    margin: 0%;
}
