.container{
    position: relative;
    width: 90%;
    color: black;
    margin-bottom: 2.5rem;
}

.course{
    position: relative;
    height: 80vh;
    width: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    background: rgb(248, 96, 96);
}
.courseName{
    display: flex;
    flex-flow: column;
    align-content:flex-start;
    text-align: left;
    padding-left: 1rem;
}

.course_id{
    text-wrap: wrap;
    text-align: left;
    font-size: 14px;
}

.course_name{
    text-wrap: wrap;
    text-align: left;
    font-size: 14px;
}
.list{
    width: 90%;
    margin-bottom: 2.5rem;
}

.topics{
    margin-top: 5px;
    margin-left: 5px;
    text-decoration: none;
    padding: 2rem;

}

.topic_header{
    display: flex;
    flex-flow: row;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
    justify-content: space-between;
    font-size: 16px;
    background-color: rgb(194 167 167)
}

.icon{
    align-self: center;
    display: flex;
    position: absolute;
}
.icon:hover{
    cursor: pointer;
}


.topics li:hover{
    cursor:pointer;
}

@media (max-width: 700px){
    .container{
        font-size: 10px;
    }
    .courseName{
        font-size: 10px;
    }
}