.container{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    width:100%;
    height: 100%;
}
.icon{
    width:2rem;
    height: 2rem;
    cursor: pointer;
}

.sideNav{
    width: 45%;
    height: 100%;
    display: flex;
    position: static;
    
}
.sideNav_hide{
    width:-40%;
}
.mediaDisplay{
    display: contents;
    height: 100%;
}

@media (max-width: 900px) {
    .container{
        flex-direction: column;
    }
    .mediaDisplay{
        width: 100%;
        height: 100%;
        margin-left: 0%;
        margin-top: 10%;
        padding: 5px;
        z-index: 0;
    }
    .icon{
        z-index: 1;
    }
    .sideNav{
        z-index: 1;
        position: absolute;
        width: 90%;
    }
    .icon_container {
        width: 10%;
        align-content: left;
    }
    
}