footer{
    display: block;
    position: fixed;
    width: 100%;
    bottom: 0rem;
    z-index: 4;

}

.wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;    
    background-color: #FCA311;
    font-size: 12px;
   
}

