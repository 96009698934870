/** Style corrected for both all display sizes */

.nav{
    margin: 1rem;
    display: flex;
    flex-flow: row;
    align-items: center;
}
.nav ul {
    list-style: none;
    margin: 1rem auto;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-evenly;
}

.nav li {
    list-style: none;
    font-size: 16px;
    margin: 1rem auto;
    padding: 0.5rem 0.1rem;
}

.nav a:hover,
.nav a:active {
    color: #14213d;
}

.nav button{
    padding: 0.5rem 0.7rem;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}
/* .btn{
    margin-top: 2rem;
    margin-right: 2rem;
}
.nav button:focus {
    outline: none;
}

.nav button:hover,
.nav button:active {
    color: #f3cafb;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
} */


@media (max-width: 700px) {
    nav{
        margin-bottom:0.2rem;
        justify-content: flex-end;
    }
}