.dashboard {
    margin-top: 5px;
    padding: 1rem;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
}

.welcome_box {
    padding: 1rem;
    width: 85%;
    margin: 2rem auto;
    border-radius: 8px;
    color: #14213d;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;

}
.user_box{
    display: flex;
    flex-flow: row;
    gap: 2rem;
    align-content: center;
    margin: 2rem;
}

.initials{
    margin: 1rem;
    width:100px;
    height:100px;
    text-align: center;
    color: white;
    font-size: 24px;
    display: flex;
}
.initials_circle{
    align-content: center;
    background-color: #14213d;
    font-size: 32px;
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.syllabus{
    position: relative;
    justify-content: flex-end;
    overflow-y: scroll;
    height: 10rem;
    width: 40rem;
}

.contents {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: #bfbdbd;
    border-radius: 8px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.6);
    margin-bottom: 2.5rem;
}

.contents p {
    width: 100%;
    text-align: center;
}

.module {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
    justify-content: center;
}

@media (max-width: 820px) {
    .module {
        width: 70rem;
    }
    .welcome_box{
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width:100%;
    }
    .user_box{
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }
    .initials{
        align-content: center;
        margin-left: 30%;
    }
    .initials_circle p{
        font-size: 32px;
    }
    .syllabus{
        position: relative;
        width: 40rem;
        overflow-x: scroll;
    }
}

@media (max-width: 600px){
    .syllabus {
        width: 20rem;
        overflow-x: scroll;
    }
}
